import { createClient, PostgrestResponse } from '@supabase/supabase-js'

// .env dosyasındaki değişkenler build zamanında yüklenecek
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Supabase URL veya Anonim Anahtar eksik.')
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

// Bağlantıyı test et
supabase.from('subscribers').select('*').limit(1).then(({ data, error }: PostgrestResponse<any>) => {
  if (error) {
    // console.error('Supabase bağlantı testi başarısız:', error)
  } else {
    // console.log('Supabase bağlantı testi başarılı')
  }
})