import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from './supabaseClient'; // Supabase client'ı import edin

const ComingSoon: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [error, setError] = useState<string | null>(null); // Hata mesajı için state ekleyin

  useEffect(() => {
    const targetDate = new Date('2024-12-31T23:59:59').getTime();
    
    const updateCountdown = () => {
      const now = new Date().getTime();
      const difference = targetDate - now;
      
      setCountdown({
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000)
      });
    };

    const timer = setInterval(updateCountdown, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null); // Hata mesajını sıfırlayın

    const { error } = await supabase // 'data' değişkenini kaldırdık
      .from('subscribers')
      .insert([{ email }]);

    if (error) {
      setError('Kayıt sırasında bir hata oluştu. Lütfen tekrar deneyin.');
      console.error('Supabase kayıt hatası:', error.message); // Hata mesajını daha genel hale getirin
    } else {
      setIsSubmitted(true);
      setEmail('');
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center text-white p-4 bg-gray-900 overflow-hidden">
      {/* Animated background */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-700 via-blue-800 to-gray-900 opacity-50" />
        <motion.div
          className="absolute inset-0 opacity-50"
          animate={{
            backgroundPosition: ['0% 0%', '100% 100%'],
            backgroundSize: ['100% 100%', '200% 200%'],
          }}
          transition={{ repeat: Infinity, duration: 20, ease: 'linear' }}
          style={{
            backgroundImage: 'url("data:image/svg+xml,%3Csvg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cg fill="%239C92AC" fill-opacity="0.1"%3E%3Cpath d="M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z"/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")',
          }}
        />
      </div>

      {/* Content */}
      <motion.div 
        className="z-10 max-w-4xl w-full text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        {/* Logo */}
        <motion.div
          className="text-7xl md:text-9xl font-extrabold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-600"
          animate={{ scale: [1, 1.05, 1] }}
          transition={{ repeat: Infinity, duration: 5, ease: "easeInOut" }}
        >
          Knowhy
        </motion.div>

        {/* Slogan */}
        <h2 className="text-2xl md:text-3xl font-light mb-8 text-cyan-300">
          Beyond What, Discover Why
        </h2>

        {/* Description */}
        <p className="text-lg md:text-xl mb-12 max-w-2xl mx-auto text-gray-300">
          Knowhy is revolutionizing the AI landscape. We're crafting intelligent solutions that don't just process data, but understand context, uncover insights, and drive meaningful change.
        </p>

        {/* Countdown */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-12">
          {Object.entries(countdown).map(([unit, value]) => (
            <motion.div 
              key={unit}
              className="flex flex-col items-center p-4 bg-gray-800 bg-opacity-50 rounded-lg backdrop-blur-sm"
              whileHover={{ scale: 1.05 }}
            >
              <span className="text-3xl md:text-4xl font-bold text-cyan-400">{value}</span>
              <span className="text-sm uppercase text-gray-400">{unit}</span>
            </motion.div>
          ))}
        </div>

        {/* Email form */}
        <form onSubmit={handleSubmit} className="mb-8">
          <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full md:w-96 px-6 py-3 rounded-full bg-gray-800 bg-opacity-50 backdrop-blur-sm text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-cyan-400"
              required
            />
            <motion.button
              type="submit"
              className="w-full md:w-auto px-8 py-3 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full text-white font-semibold hover:from-cyan-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-cyan-400"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Get Notified
            </motion.button>
          </div>
        </form>

        {/* Submission message */}
        <AnimatePresence>
          {isSubmitted && (
            <motion.div 
              className="mb-8 text-cyan-400 font-semibold"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
            >
              Thank you! We'll keep you updated on our launch.
            </motion.div>
          )}
        </AnimatePresence>

        {/* Error message */}
        {error && (
          <div className="mb-8 text-red-400 font-semibold">
            {error}
          </div>
        )}

        {/* Social media links */}
        <div className="flex justify-center space-x-6 mb-8">
          {['twitter', 'linkedin', 'github', 'instagram'].map((social) => (
            <motion.a
              key={social}
              href={`https://${social}.com/knowhy`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-2xl text-gray-400 hover:text-cyan-400 transition-colors duration-300"
              whileHover={{ scale: 1.2, rotate: 5 }}
            >
              <i className={`fab fa-${social}`} aria-hidden="true"></i>
              <span className="sr-only">{`Knowhy on ${social}`}</span>
            </motion.a>
          ))}
        </div>

        {/* Contact info */}
        <div className="text-sm text-gray-400">
          Contact us: <a href="mailto:info@knowhy.co" className="underline hover:text-cyan-400 transition-colors duration-300">info@knowhy.co</a>
        </div>
      </motion.div>
    </div>
  )
}

export default ComingSoon;